.root {
  background: #eeecf8;
  height: 100%;
  margin: 0 -48px;
  margin-top: -16px;
  padding: 24px;
}

.body {
  max-width: 632px;
  margin: 0 auto;
  animation: animateRoot 0.5s ease forwards;
}

@keyframes animateRoot {
  from {
    transform: translateY(10%);
    opacity: 0;
  }
  to {
    transform: translateY(0px);
    opacity: 1;
  }
}

.checkboxGroupRoot {
  align-items: inherit !important;
}

.checkboxGroup {
  gap: 16px;
}

.checkboxLabel {
  color: #272522;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.28px;
  margin-top: -2px;
  user-select: none;
}

.checkboxSubLabel {
  color: rgba(39, 37, 34, 0.5);
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.28px;
  user-select: none;
  margin-top: 6px;
}

.cardLabel {
  color: rgba(39, 37, 34, 0.6);
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.28px;
  margin-bottom: 20px;
  user-select: none;
}

@media screen and (max-width: 768px) {
  .root {
    padding: 16px;
    padding-bottom: calc(16px + 68px);
    margin: 0 -16px;
    margin-top: -16px;
  }
}
