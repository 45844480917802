.question {
  font-weight: 600;
  font-size: 14px;
  color: #000;
}

.answer {
  font-size: 14px;
  opacity: 0.7;
}

.remark_container {
  margin: 10px 0;
}

.bookings_feedback_percentage {
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 22px;
  transform: translate(-50%, -50%);
  z-index: -1;
}

.popover_paper {
  border-radius: 5px;
  box-shadow: -2px 2px 4px rgba(0, 0, 0, 0.14);
  border: 1px solid #e7e6e4;
}

.overallRatingText {
  line-height: 200px;
}

.rating_stars {
  display: flex;
  justify-content: center;
}

.mobile_secondary_action {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: rgba(255, 255, 255, 0.85);
}
.mobile_footer {
  padding-right: 9.4px !important;
}
.mobile_footer_1 {
  background-color: #df635d !important;
}
.mobile_footer_2 {
  background-color: #ffab00 !important;
}
.mobile_footer_3 {
  background-color: #0052cc !important;
}
.mobile_footer_4 {
  background-color: #006644 !important;
}
.mobile_footer_5 {
  background-color: #00b779 !important;
}

.mobile_rating_1 {
  background-color: rgba(253, 219, 219, 1) !important;
}
.mobile_rating_2 {
  background-color: rgba(248, 233, 222, 1) !important;
}
.mobile_rating_3 {
  background-color: rgba(255, 248, 212, 1) !important;
}
.mobile_rating_4 {
  background-color: rgba(216, 243, 244, 1) !important;
}
.mobile_rating_5 {
  background-color: rgba(216, 243, 244, 1) !important;
}

.feedback_form_modal {
  max-width: 1200px !important;
}

.feedback_detail_modal {
  min-width: 35% !important;
}

.detail_row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid #e7e6e4;
  align-items: center;
  padding: 10px 0;
}

.detail_title {
  font-weight: 600;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.85);
}

.detail_description {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.85);
}

.item {
  padding: 16px;
  font-size: 16px;
  line-height: 19px;
}

.edit_delete {
  padding: 16px;
  font-size: 16px;
  line-height: 19px;
  color: #493ab1;
  display: flex;
  align-items: center;
  gap: 10px;
}

.edit_delete:hover {
  cursor: pointer;
}

.item:hover {
  cursor: pointer;
  background: #e7e6e4;
}

.item:last-child {
  border-bottom: none;
}

.options_modal {
  background-color: #fff;
  box-shadow: 0px 2px 4px gray;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  padding: 5px 0;
  align-self: center;
  justify-content: center;
  float: left;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  max-height: 90vh;
  overflow: auto;
  outline: none;
}

.week_modal {
  white-space: nowrap;
}

.headerContainer {
  margin-bottom: 16px;
  margin: 0px 0px 24px 0px;
  padding: 0px 0px 2rem 0px;
  padding-top: 0px !important;
}

@media screen and (max-width: 780px) {
  .overallRatingText {
    line-height: 30px;
  }

  .week_modal {
    padding: 10px 0 37px 0;
  }

  .item {
    padding: 10px 16px;
    font-size: 16px;
    line-height: 19px;
  }
}
