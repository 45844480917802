.root {
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
}

.titleContainer {
  display: flex;
  align-items: center;
  color: #272522;
  font-size: 24px;
  font-weight: 700;
  line-height: normal;
  gap: 12px;
  animation: animateRoot 0.5s ease forwards;
}

.titleContainer button {
  color: #272522;
  padding: 0;
}

.buttonWrapperClassName {
  animation: animateRoot 0.5s ease forwards;
}

@keyframes animateRoot {
  from {
    transform: translateY(20%);
    opacity: 0;
  }
  to {
    transform: translateY(0px);
    opacity: 1;
  }
}

@media screen and (max-width: 768px) {
  .root {
    padding: 20px 16px;
    border-bottom: 1.5px solid #e7e6e4;
    min-height: 67px;
    max-height: 67px;
  }

  .titleContainer {
    font-size: 18px;
  }
  .mobileCtaWrapper {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 12px 16px;
    border-top: 2px solid #e7e6e4;
    background: white;
    z-index: 1;
    animation: animateRoot 0.5s ease forwards;
  }

  .buttonWrapperClassName {
    animation: none;
  }

  @keyframes animateRoot {
    from {
      transform: translateY(100%);
    }
    to {
      transform: translateY(0px);
    }
  }
}
