.mt_up {
  margin-top: -24px !important;
}

.fullWidth {
  width: 100%;
}

.f_12 {
  font-size: 12px !important;
  line-height: 14px;
}

.f_14 {
  font-size: 14px !important;
}

.padding0 {
  padding: 0 !important;
}

.colorPrimaryMain {
  color: #493ab1 !important;
}

.colorSecondary {
  color: #272522;
}

.addMemberButton {
  padding: 0 30px !important;
}

.roleIcon {
  width: 40px;
  height: 40px;
  margin-right: 10px;
}

.tableDescription {
  color: #272522;
  margin-bottom: 24px !important;
  width: calc(100% - 258px);
  margin-top: -4px !important;
}

.memberDetailsWrap {
  display: flex;
  gap: 42px;
  width: 70%;
  margin: 30px auto;
}

.addMemberBtn {
  flex-grow: 1;
  display: flex;
  align-items: flex-end;
}

.membersHolder {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 20px;
}

.photoWidgetWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
}

.linkButton {
  font-size: 14px !important;
  justify-content: center !important;
}

.displayPicture {
  border-radius: 50%;
  width: 120px;
  height: 120px;
}

.mobileAction {
  padding: 7px;
  font-size: 16px;
  line-height: 19px;
}

.orgMemberCard {
  gap: 12px;
  min-height: 344px;
  border-radius: 0px 3px 0px rgba(0, 0, 0, 0.14);
}

.noMembersPlaceholder {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 24px;
  max-width: 1200px;
}

.displayIcon {
  width: 62px;
  height: 62px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}

.memberProfileContainer {
  height: 100vh;
  position: relative;
  overflow-y: hidden;
}

.navContainer {
  position: absolute;
  top: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px;
  padding: 0 24px;
  height: 68px;
  border-bottom: 2px solid #e7e6e4;
}

.profileMainContent {
  position: absolute;
  top: 68px;
  width: 100%;
  overflow-y: auto;
}

.profileFooter {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
  border-top: 2px solid #e7e6e4;
  background-color: white;
  position: fixed;
  bottom: 0;
  height: 66px;
  padding: 0 16px;
  width: 100%;
}

.backIconButton svg {
  fill: #272522;
}

.profileName {
  font-size: 16px;
  font-weight: 700;
  text-transform: capitalize;
}

.profileBackWrap {
  display: flex;
  align-items: center;
  gap: 12px;
}

.moreActionsBtn {
  min-width: 0 !important;
  padding: 0 10.5px !important;
}

.assignButton {
  white-space: nowrap !important;
}

.desktopProfileActions {
  display: flex;
  gap: 24px;
  align-items: center;
}

.profileMobileActionWrap {
  padding: 9px 0 17px 0;
}

.profileActionLink {
  font-size: 16px;
  padding: 6px 16px;
  color: #272522;
  cursor: pointer;
}

.profileActionLink:hover {
  background-color: #e7e6e4;
}

.flexContainer {
  padding-left: 24px;
}

.tabIndicator {
  margin-left: 24px;
}

.profileCarousel {
  padding: 40px 16px 24px 16px;
  overflow-x: hidden;
  margin-top: 16px;
}

.profileCarousel .slick-slide {
  width: 138px !important;
}

.carouselDots {
  top: 0 !important;
  right: 0 !important;
  width: auto !important;
  bottom: unset !important;
  display: inline-block !important;
}

.mobileStatsCard {
  min-width: 122px !important;
  height: 138px !important;
  width: 80% !important;
}

.desktopProfileCardWrap {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 24px;
  margin: 24px;
}

@media (max-width: 1000px) {
  .noMembersPlaceholder {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .photoWidgetWrapper {
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
  }

  .memberDetailsWrap {
    flex-direction: column;
    width: 100%;
    gap: 24px;
    margin: 0 auto;
  }

  .noMembersPlaceholder {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 20px;
  }

  .membersHolder {
    grid-template-columns: repeat(1, 1fr);
  }

  .memberProfileContainerPadded {
    padding-bottom: 68px;
  }

  .navContainer {
    padding: 0 16px;
    height: 56px;
  }

  .profileMainContent {
    top: 56px;
  }

  .flexContainer {
    padding-left: 16px;
  }

  .tabIndicator {
    margin-left: 16px;
  }
}
